<template>
  <div>
    <h1>Promoções</h1>
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="roles && roles.length > 0">
      <div v-for="role in roles" :key="role.role" class="mt-2">
        <h3>{{ role.role }}</h3>
        <div v-for="step in role.steps" :key="step.step" class="ml-1">
          <h4>Etapa {{ step.step }}</h4> 
          <small class="ml-1" v-if="!pastStepCompleted(role.role, step.step)">Etapa anterior Pendente...</small>
          <div v-else>
            <b-row v-for="item in step.items" :key="step.id">
              <b-col md="4" sm="12" class="mb-1 ml-1" >
                <template v-if="item.type == 'signer'">
                  <small class="text-muted"> {{ item.description }} </small>
                  <div class="d-flex align-items-center">
                    <h5 v-if="item.finished_at">
                      {{ item.signer_email }}
                    </h5>
                    <template v-else>
                      <b-form-input
                        v-model="item.signer_email"
                        placeholder="E-mail do assinante"
                      />
                      <b-button
                        size="sm"
                        variant="primary"
                        class="ml-1"
                        @click="updateStepField(item.id, item.signer_email)"
                      >
                        Salvar
                      </b-button>
                    </template>
                  </div>
                </template>
                <div v-if="item.type == 'document'" class="mt-1">
                  {{ item.description }}
                  - 
                  <strong v-if="item.finished_at">Assinado em {{ item.finished_at | dateTime }}</strong>
                  <span v-else>Pendente</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex justify-content-center">
        <h5>Não há nenhuma promoção pendente para o consultor</h5>
      </div>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/modules/consultant/store/types";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { dateToShortFormat } from "@/helpers/converters";
import { data } from "vue-echarts";

export default {
  components: {
    
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      pendingPromotionSteps: types.PENDING_PROMOTION_STEPS,
    }),
    roles: function() {
      if(this.pendingPromotionSteps && this.pendingPromotionSteps.length > 0){
        return _(this.pendingPromotionSteps)
          .groupBy('consultant_role')
          .map((objs, key) => ({
            'role': key,
            'steps': _(objs)
              .groupBy('step')
              .map((objs, key) => ({
                'step': key,
                'items': objs.map(obj => {
                  return {
                    id: obj.id,
                    description: obj.checklist_item.description,
                    type: obj.checklist_item.type,
                    finished_at: obj.finished_at,
                    signer_email: obj.signer_email
                  }
                })
              }))
              .value()
          }))
          .value();
      }
      return []
    }
  },
  mounted() {
    this.loading = true;
    this.get();
  },
  methods: {
    ...mapActions({
      getPendingPromotionSteps: types.GET_PENDING_PROMOTION_STEPS,
      updatePromotionStep: types.UPDATE_PROMOTION_STEP
    }),
    get() {
      this.loading = true
      this.getPendingPromotionSteps(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as etapas de promoção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateStepField(step_id, value){
      this.updatePromotionStep({
        consultant_id: this.$route.params.id,
        step_id, 
        signer_email: value
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Etapa atualizada com sucesso.",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.get()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar a etapa. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    pastStepCompleted(role, step){
      const roleData = _.find(this.roles, { role: role })
      if(roleData){
        const stepData = _.find(roleData.steps, { step: step })
        if(stepData){
          const previousStep = _.find(roleData.steps, (data) => { return parseInt(data.step) == parseInt(step) - 1 })
          if(previousStep){
            return _.every(previousStep.items, (data) => { return data.finished_at })
          }
        }
      }
      return true
    }
  },
  
};
</script>
